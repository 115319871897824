@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  transition: background-color 0.5s, color 0.5s;
}

body {
  margin: 0;
}

html.dark {
  background-color: #111827;
  color: #f9fafb;
}

html {
  background-color: #f9fafb;
  color: #111827;
}
